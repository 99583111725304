<template>
  <div class="site">
    <!--顶部导航-->
    <Nav :blogName="siteInfo.blogName" :categoryList="categoryList" />
    <!--首页大图 只在首页且pc端时显示-->
    <div class="m-mobile-hide">
      <Header v-if="$route.name === 'home'" />
    </div>

    <div class="main">
      <div class="m-padded-tb-big">
        <div class="ui container">
          <div class="ui stackable grid">
            <!--左侧-->
            <!-- <div class="three wide column m-mobile-hide">
							<Introduction :class="{'m-display-none':focusMode}"/>
						</div> -->
            <!--中间-->
            <div class="thirteen wide column">
              <keep-alive include="Home">
                <router-view />
              </keep-alive>
            </div>
            <!--右侧-->
            <div class="three wide column m-mobile-hide">
              <RandomBlog
                :randomBlogList="randomBlogList"
                :class="{ 'm-display-none': focusMode }"
              />
              <Tags
                :tagList="tagList"
                :class="{ 'm-display-none': focusMode }"
              />
              <!--只在文章页面显示目录-->
              <Tocbot v-if="$route.name === 'blog'" />
            </div>
          </div>
        </div>
      </div>
    </div>

    <!--私密文章密码对话框-->
    <BlogPasswordDialog />

    <!--APlayer-->
    <div class="m-mobile-hide">
      <!-- <MyAPlayer /> -->
    </div>
    <!--回到顶部-->
    <el-backtop>
      <!-- <img src="/img/paper-plane.png" style="width: 40px; height: 40px" /> -->
    </el-backtop>
    <!--底部footer-->
    <Footer
      :siteInfo="siteInfo"
      :badges="badges"
      :newBlogList="newBlogList"
      :hitokoto="hitokoto"
    />
  </div>
</template>

<script>
import { getHitokoto, getSite } from '@/api/index';
import Nav from '@/components/index/Nav';
import Header from '@/components/index/Header';
import Footer from '@/components/index/Footer';
import Introduction from '@/components/sidebar/Introduction';
import Tags from '@/components/sidebar/Tags';
import RandomBlog from '@/components/sidebar/RandomBlog';
import MyAPlayer from '@/components/index/MyAPlayer';
import Tocbot from '@/components/sidebar/Tocbot';
import BlogPasswordDialog from '@/components/index/BlogPasswordDialog';
import { mapState } from 'vuex';
import {
  SAVE_CLIENT_SIZE,
  SAVE_INTRODUCTION,
  SAVE_SITE_INFO,
  RESTORE_COMMENT_FORM,
} from '@/store/mutations-types';

export default {
  name: 'Index',
  components: {
    Header,
    BlogPasswordDialog,
    Tocbot,
    MyAPlayer,
    RandomBlog,
    Tags,
    Nav,
    Footer,
    Introduction,
  },
  data() {
    return {
      siteInfo: {
        blogName: '',
      },
      categoryList: [],
      tagList: [],
      randomBlogList: [],
      badges: [],
      newBlogList: [],
      hitokoto: {},
    };
  },
  computed: {
    ...mapState(['focusMode']),
  },
  watch: {
    //路由改变时，页面滚动至顶部
    '$route.path'() {
      this.scrollToTop();
    },
  },
  created() {
    this.getSite();
    this.getHitokoto();
    //从localStorage恢复之前的评论信息
    this.$store.commit(RESTORE_COMMENT_FORM);
  },
  mounted() {
    //保存可视窗口大小
    this.$store.commit(SAVE_CLIENT_SIZE, {
      clientHeight: document.body.clientHeight,
      clientWidth: document.body.clientWidth,
    });
    window.onresize = () => {
      this.$store.commit(SAVE_CLIENT_SIZE, {
        clientHeight: document.body.clientHeight,
        clientWidth: document.body.clientWidth,
      });
    };
  },
  methods: {
    getSite() {
      getSite().then((res) => {
        if (res.code === 200) {
          this.siteInfo = res.data.siteInfo;
          this.badges = res.data.badges;
          this.newBlogList = res.data.newBlogList;
          this.categoryList = res.data.categoryList;
          this.tagList = res.data.tagList;
          this.randomBlogList = res.data.randomBlogList;
          this.$store.commit(SAVE_SITE_INFO, this.siteInfo);
          this.$store.commit(SAVE_INTRODUCTION, res.data.introduction);
          document.title =
            this.$route.meta.title + this.siteInfo.webTitleSuffix;
        }
      });
    },
    //获取一言
    getHitokoto() {
      this.hitokoto = {
        from: 'BCH101.COM',
        hitokoto:
          'BCH101is an all-in-one introductory site on BCH. It is available for crypto lovers of all ages and levels. \n BCH101是BCH的最全的介绍网站，内容覆盖BCH每周动态、科普视频、网络讨论会，项目汇总和聚会信息。适合各年龄各水平的加密货币爱好者。',
      };
      // getHitokoto().then((res) => {
      //   this.hitokoto = res;
      // });
    },
  },
};
</script>

<style scoped>
.site {
  display: flex;
  min-height: 100vh; /* 没有元素时，也把页面撑开至100% */
  flex-direction: column;
}

.main {
  margin-top: 40px;
  flex: 1;
}

.main .ui.container {
  width: 1400px !important;
  margin-left: auto !important;
  margin-right: auto !important;
}

.ui.grid .three.column {
  padding: 0;
}

.ui.grid .ten.column {
  padding-top: 0;
}

.m-display-none {
  display: none !important;
}
</style>