<template>
  <div>
    <el-dropdown @command="handleSetLang" trigger="click" style="height: 100%">
      <div class="lang-active el-dropdown-link item">
        <div
          v-for="(lang, i) in langs"
          :key="`LangActive${i}`"
          v-show="lang.key === activeLang"
        >
          {{ lang.value }} <i class="caret down icon"></i>
        </div>
      </div>
      <el-dropdown-menu slot="dropdown">
        <el-dropdown-item
          v-for="(lang, i) in langs"
          :key="`Lang${i}`"
          :command="lang.key"
        >
          <span class="text">{{ lang.value }}</span></el-dropdown-item
        >
      </el-dropdown-menu>
    </el-dropdown>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex';

export default {
  name: 'Lang',
  components: {},
  data() {
    return {
      langs: [
        {
          key: 'zh-CN',
          value: '中文',
        },
        {
          key: 'en-US',
          value: 'EngLish',
        },
        // {
        //   key: 'Korean',
        //   value: 'Korean',
        // },
        // {
        //   key: 'vi_VN',
        //   value: 'Vietnam',
        // },
      ],
    };
  },
  computed: {
    ...mapGetters({
      activeLang: 'language',
    }),
  },
  created() {},
  mounted() {},
  methods: {
    ...mapMutations(['setLanguage']),
    handleSetLang(lang) {
      // 设置i18n.locale 组件库会按照上面的配置使用对应的文案文件
      this.$i18n.locale = lang;
      // 提交mutations
      this.setLanguage(lang);
      console.log('lang===>', lang);
      console.log(this.$route.path, '111222333');
      if (this.$route.path === '/' || this.$route.path === '/home') return;
      this.$router.push('/');
    },
  },
};
</script> 
