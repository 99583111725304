var zh={
  home:'首页',
  category:'分类',
  archives:'归档',
  moments:'动态',
  nav:'导航',
  about:'关于',
  smartBCH:'smartBCH',
  BCHBasedProjects :'BCH网络项目汇总',
  WhatisBitcoinCash:'什么是比特币现金',
  BCHWeeklyNews:'BCH每周要闻',
  smartBCHTopic :'smartBCH专题',
  view:'阅读全文',
  wallet:'连接钱包',
  airdrop:'领取空投',
  footer:{
    mobile:"手机看本站",
    new:'最新文章',
  },
  blog:{
    author:'作者:',
    time:'发表时间:',
    modified:'最后修改:',
    desc1:'本站点采用',
    desc2:'署名 4.0 国际 (CC BY 4.0)',
    desc3:'创作共享协议。可自由转载、引用，并且允许商业性使用。但需署名作者且注明文章出处。',
  },
  comment:{
    close:'评论已关闭',
    comment:'发表评论',
    btn:'可以让我参观一下吗😊',
    notice:'订阅回复',
    email:'用于接收回复邮件',
    email_placeholder:'邮箱（必填）',
    qq:'输入QQ号将自动拉取昵称和头像',
    qq_placeholder:'昵称（必填）',
    total:'条评论',
    desc:'快来抢沙发！',
    reply:'回复',
    close_reply:'取消回复',  
    textarea:'评论千万条，友善第一条'
  },
  artice:{
    menu:'本文目录',
    artice:'随机文章',
    label:'标签云'
  },
  share:{
    desc:'分享到:',
    copySuccess:'分享链接成功',
    copyError:'分享链接失败',
  }
}

export default zh