import {mapGetters, mapState} from "vuex";

const web3Modal = {
    computed: {
        ...mapState(['web3Modal']),
        // ...mapGetters(['predictionsContract'])
    },
    active() {
        return this.web3Modal.active
    },
    account() {
        return this.web3Modal.account
    },
    conutNum() {
        return this.web3Modal.conutNum
    }
}
export default web3Modal
