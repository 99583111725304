export const sort_pro = function(data, keys = []) {     //keys可以传一个数组
  var c = [];
  var d = {};
  for (var element of data) {
    let element_keyStr = "";
    let element_key = [];
    let element_keyObj = {};
    for (var key of keys) {
      element_key.push(element[key]);
      element_keyObj[key] = element[key];
    }
    element_keyStr = element_key.join("_");
    if (!d[element_keyStr]) {
      c.push({
        ...element_keyObj,
        list: [element]
      });
      d[element_keyStr] = element;
    } else {
      for (var ele of c) {
        let isTrue = keys.some(key => {
          return ele[key] != element[key];
        });
        if (!isTrue) {
          ele.list.push(element);
        }
      }
    }
  }
  return c;
}