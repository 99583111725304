import axios from '@/plugins/axios'
import config from '@/util/config.js';
// 获取积分
export function getScores(params) {
	return axios({
		// url:	process.env.NODE_ENV === 'production'? 'https://bch101.com/api/v1/user/getScores' :"http://120.27.244.106:3000/api/v1/user/getScores",
		url: `${config.scoreURL}v1/user/getScores`,
		method: 'POST',
		data: {
			...params
		}
	})
}
//增加积分
export function subScores(params) {
	return axios({
		// url: 	process.env.NODE_ENV === 'production'? 'https://bch101.com/api/v1/user/subScores' :'http://120.27.244.106:3000/api/v1/user/subScores',
		url: `${config.scoreURL}v1/user/subScores`,
		method: 'POST',
		data: {
			...params
		}
	})
}
//查询阅读数
export function getUserReadCount(params) {
	return axios({
		// url:	process.env.NODE_ENV === 'production'? 'https://bch101.com/api/v1/user/getReadCount' :'http://120.27.244.106:3000/api/v1/user/getReadCount',
		url: 	`${config.scoreURL}v1/user/getReadCount`,
		method: 'POST',
		data: {
			...params
		}
	})
}
//查询纪录
export function getScoresRecs(params) {
	return axios({
		// url: 	process.env.NODE_ENV === 'production'?'https://bch101.com/api/v1/user/getScoresRecs':'http://120.27.244.106:3000/api/v1/user/getScoresRecs',
		url: `${config.scoreURL}v1/user/getScoresRecs`,
		method: 'POST',
		data: {
			...params
		}
	})
}