<template>
	<meting-js server="netease" type="playlist" id="3071528549" fixed="true" theme="#25CCF7"></meting-js>
</template>

<script>
	export default {
		name: "MyAPlayer"
	}
</script>

<style scoped>

</style>