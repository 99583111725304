var en={
  home:'Home',
  category:'category',
  archives:'archives',
  moments:'moments',
  nav:'Category',
  about:'about',
  smartBCH:'smartBCH Sidechain',
  BCHBasedProjects :'BCH-based Projects',
  WhatisBitcoinCash:'What is Bitcoin Cash',
  BCHWeeklyNews:'BCH Weekly News',
  smartBCHTopic :'smartBCH Topic',
  view:'Read full aritcle',
  wallet:'Connect the wallet',
  airdrop:'Claim Airdrop',
  footer:{
    mobile:"Mobile",
    new:'Latest Articles',
  },
  blog:{
    author:'The author:',
    time:'Publication Time:',
    modified:'Last modified:',
    desc1:'This site adopts',
    desc2:'Signature 4.0 International (CC BY 4.0)',
    desc3:'Creative Commons agreement. Can be reproduced freely, quote, and allow commercial use. But the author must be signed and the source of the article cited.',
  },
  comment:{
    close :' Comment closed ',
    comment :' Comment ',
    btn :' Can I have a tour 😊',  
    notice :' Subscribe to reply ',
    email :' for receiving replies ',
    email_placeholder :' mailbox (required) ',
    qq :' Enter qq number will automatically pull the nickname and avatar ', 
    qq_placeholder :' nickname ',  
    total :' comment ', 
    desc :' Grab the sofa! ',
    reply: 'reply',  
    close_reply :' cancel reply ', 
    textarea :' Thousands of comments, be kind number one '
  },
  artice:{
    menu :' This article directory ',
    artice:'Random articles',
    label:'Tag cloud'
  },
  share:{
    desc:'Share:',
    CopySuccess :' Share link successfully ',  
    CopyError :' Failed to share link ', 
  }
}

export default en