<template>
  <div class="share-content">
    {{ $t('share.desc') }}
    <div class="share-btn" @click="windowSmallOpen(socialLink.weibo)">
      <div class="share-bg">
        <img class="share-icon weibo" src="@/assets/img/weibo.svg" />
      </div>
    </div>
    <div class="share-btn" @click="windowSmallOpen(socialLink.facebook)">
      <div class="share-bg">
        <img class="share-icon facebook" src="@/assets/img/facebook.svg" />
      </div>
      <!-- <p>Facebook</p> -->
    </div>
    <div class="share-btn" @click="windowSmallOpen(socialLink.twitter)">
      <div class="share-bg">
        <img class="share-icon twitter" src="@/assets/img/twitter.svg" />
      </div>
      <!-- <p>Twitter</p> -->
    </div>
    <div class="share-btn" @click="windowSmallOpen(socialLink.qq)">
      <div class="share-bg">
        <img class="share-icon qq" src="@/assets/img/qq.svg" />
      </div>
      <!-- <p>QQ</p> -->
    </div>
    <div class="share-btn" @click="windowSmallOpen(socialLink.qzone)">
      <div class="share-bg">
        <img class="share-icon qzone" src="@/assets/img/qzone.svg" />
      </div>
      <!-- <p>qq空间</p> -->
    </div>
    <!-- <div class="share-btn" @click="windowSmallOpen(socialLink.telegram)">
      <div class="share-bg">
        <img class="share-icon telegram" src="@/assets/img/telegram.svg" />
      </div>
    </div> -->
    <div
      class="share-btn"
      v-clipboard:copy="message"
      v-clipboard:success="onCopy"
      v-clipboard:error="onError"
    >
      <div class="share-bg">
        <img class="share-icon telegram" src="@/assets/img/link.svg" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'SocialShare',
  components: {
    // wechat
  },
  props: {
    article: {
      type: Object,
      default: () => {
        return {};
      },
    },
    qqTitle: {
      type: String,
      default: '',
    },
    summary: {
      type: String,
      default: '',
    },
  },
  computed: {
    message() {
      return window.location.href;
    },
    cover() {
      // if (this.article.cover) return this.$ossProcess(this.article.cover);
      return 'https://bch101.com/img/bg2.c2e52d1f.png';
    },
    socialLink() {
      const title = this.article.title;
      let link = window.location.href;
      const pic = (document.images[0] || 0).src || this.cover;
      // 用于 Telegram 的即时预览，模板出问题了联系我 --Frank
      // const telegramIVLink = encodeURIComponent(
      //   `https://t.me/iv?url=${link}&rhash=${process.env.TELEGRAM_IV_RHASH}`
      // );

      const shortContent =
        this.article.content && this.article.content.slice(3, 120);
      // const shortContent = this.article.title;
      return {
        weibo: `http://service.weibo.com/share/share.php?&title=${encodeURIComponent(
          `${title} - 来自 BCH101 ${link}`
        )}&url=${link}&pic=${pic}&searchPic=false&style=simple`,
        facebook: `https://www.facebook.com/sharer/sharer.php?u=${link}`,
        twitter: `https://twitter.com/intent/tweet?text=${title}&url=${link}`,
        telegram: `https://t.me/share/url?url=${link}`,
        qq: `http://connect.qq.com/widget/shareqq/index.html?url=${link}&sharesource=qzone&title=${title}&pics=${pic}&summary=${shortContent}`,
        qzone: `https://sns.qzone.qq.com/cgi-bin/qzshare/cgi_qzshare_onekey?url=${link}&sharesource=qzone&title=${title}&pics=${pic}&summary=${shortContent}`,
      };
    },
  },
  mounted() {},
  methods: {
    windowSmallOpen(url) {
      window.open(url, '_blank', 'width=760,height=640');
    },
    onCopy: function (e) {
      this.msgSuccess(this.$t('share.copySuccess') + ':' + e.text);
    },
    onError: function (e) {
      this.msgError(this.$t('share.copyError'));
    },
  },
};
</script>
 <style lang="less" scoped>
.share-content {
  display: flex;
  align-items: center;
  justify-content: space-around;
  line-height: 58px;
  .share-btn {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin: 0 10px;
    cursor: pointer;
    // .share-icon {
    //   font-size: 2em;
    // }
  }
}

.wx-share {
  text-align: center;
  margin-top: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  .qrcode {
    background: #ffffff;
    width: 120px;
    height: 120px;
  }
}
.qrcode-container {
  background: #ffffff;
  padding: 5px;
}
p {
  margin: 10px 0 0 0;
  padding: 0;
  font-size: 14px;
  font-weight: 400;
  color: #000000;
}
.share-bg {
  width: 30px;
  height: 30px;
  background-color: #fff;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  .share-icon {
    width: 60%;
  }
  .weibo {
    color: #ff5722;
  }
  .facebook {
    color: #3c5999;
  }
  .twitter {
    color: #00aced;
  }
  .telegram {
    color: #36aae8;
  }
  .qq {
    color: #4cafe9;
  }
  .qzone {
    color: #fab619;
  }
}
.code {
  margin-top: 30px;
  .icon {
    color: #24db5a;
    font-size: 22px;
    margin-left: 4px;
  }
}
</style>