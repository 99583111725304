let prodConfig = {
  prodOrDev: 'production', //正式环境
  baseURL: process.env.VUE_APP_URL, 
  scoreURL: process.env.VUE_APP_SCOREURL, 
};

let testConfig = {
  prodOrDev: 'test', //测试环境
  baseURL: process.env.VUE_APP_URL, 
  scoreURL: process.env.VUE_APP_SCOREURL,
};

let devConfig = {
  prodOrDev: 'dev', //开发环境
  baseURL: process.env.VUE_APP_URL, 
  scoreURL: process.env.VUE_APP_SCOREURL, 
};

const config = process.env.NODE_ENV === 'development'
               ? devConfig
               : process.env.VUE_APP_TITLE === 'test'
               ? testConfig 
               : process.env.VUE_APP_TITLE === 'production'
               ? prodConfig
               : devConfig;

export default config;
