<template>
  <div
    ref="nav"
    class="ui fixed inverted stackable pointing menu"
    :class="{
      transparent: $route.name === 'home' && clientSize.clientWidth > 768,
    }"
  >
    <!-- {{ storeLang }} -->
    <div class="ui container">
      <router-link to="/">
        <h3 class="ui header item m-green">{{ blogName }}</h3>
      </router-link>
      <router-link
        to="/home"
        class="item"
        :class="{ 'm-mobile-hide': mobileHide, active: $route.name === 'home' }"
      >
        {{ $t('home') }}
      </router-link>
      <router-link
        to="/friends"
        class="item"
        :class="{
          'm-mobile-hide': mobileHide,
          active: $route.name === 'friends',
        }"
      >
        {{ $t('nav') }}
      </router-link>
      <router-link
        :to="`/category/${$t('smartBCH')}`"
        class="item"
        :class="{
          'm-mobile-hide': mobileHide,
          active:
            decodeURIComponent($route.path) === `/category/${$t('smartBCH')}`,
        }"
      >
        {{ $t('smartBCH') }}
        <!-- smartBCH -->
      </router-link>
      <router-link
        :to="`/category/${$t('BCHBasedProjects')}`"
        class="item"
        :class="{
          'm-mobile-hide': mobileHide,
          active:
            decodeURIComponent($route.path) ===
            `/category/${$t('BCHBasedProjects')}`,
        }"
      >
        {{ $t('BCHBasedProjects') }}
        <!-- BCH网络项目汇总 -->
      </router-link>
      <router-link
        :to="`/category/${$t('WhatisBitcoinCash')}`"
        class="item"
        :class="{
          'm-mobile-hide': mobileHide,
          active:
            decodeURIComponent($route.path) ===
            `/category/${$t('WhatisBitcoinCash')}`,
        }"
      >
        {{ $t('WhatisBitcoinCash') }}
        <!-- 什么是比特币现金 -->
      </router-link>
      <router-link
        :to="`/category/${$t('BCHWeeklyNews')}`"
        class="item"
        :class="{
          'm-mobile-hide': mobileHide,
          active:
            decodeURIComponent($route.path) ===
            `/category/${$t('BCHWeeklyNews')}`,
        }"
      >
        {{ $t('BCHWeeklyNews') }}
        <!-- BCH每周要闻 -->
      </router-link>
      <a
        v-if="formData && formData.form_url"
        target="_blank"
        class="item"
        :class="{
          'm-mobile-hide': mobileHide,
        }"
        :href="formData.form_url"
      >
        {{ $t('airdrop') }}</a
      >
      <!-- BCH专题 -->
      <!-- <router-link
        :to="`/category/${$t('smartBCHTopic')}`"
        class="item"
        :class="{
          'm-mobile-hide': mobileHide,
          active:
            decodeURIComponent($route.path) ===
            `/category/${$t('smartBCHTopic')}`,
        }"
      >
        {{ $t('smartBCHTopic') }}
      </router-link> -->
      <x-lang
        :class="{
          'm-mobile-hide': mobileHide,
        }"
      />
      <el-autocomplete
        v-model="queryString"
        :fetch-suggestions="debounceQuery"
        placeholder="Search..."
        class="right item m-search"
        :class="{ 'm-mobile-hide': mobileHide }"
        popper-class="m-search-item"
        @select="handleSelect"
      >
        <i class="search icon el-input__icon" slot="suffix"></i>
        <template slot-scope="{ item }">
          <div class="title">{{ item.title }}</div>
          <span class="content">{{ item.content }}</span>
        </template>
      </el-autocomplete>

      <el-button
        size="small"
        round
        class="right item"
        style="height: 30px; margin-top: 10px"
        v-if="web3Modal.active"
        @click="toCheck()"
      >
        {{ web3Modal.account.slice(0, 5) }} ...
        {{ web3Modal.account.slice(-5) }}
        <el-badge :value="countNum" style="z-index: 10"></el-badge>
      </el-button>

      <el-button
        v-else
        size="small"
        round
        @click="connect()"
        class="right item"
        style="height: 30px; margin-top: 10px"
      >
        {{ $t('wallet') }}
      </el-button>
      <button
        class="ui menu green icon button m-right-top m-mobile-show"
        @click="toggle"
      >
        <i class="sidebar icon"></i>
      </button>
    </div>
    <web3-modal-vue
      ref="web3modal"
      :theme="theme"
      :provider-options="providerOptions"
      cache-provider
    />
  </div>
</template>

<script>
import { getSearchBlogList } from '@/api/blog';
import { getCurrentFormUrl } from '@/api/form';
import { mapState } from 'vuex';
import XLang from '../lang/index.vue';

import Web3ModalVue from 'web3modal-vue';
import WalletConnectProvider from '@walletconnect/web3-provider';
import web3Modal from '@/config/mixins';
import { getStorage, setStorage, getBrowserLang } from '@/util/store.js';

export default {
  name: 'Nav',
  props: {
    blogName: {
      type: String,
      required: true,
    },
    categoryList: {
      type: Array,
      required: true,
    },
  },
  components: {
    XLang,
    Web3ModalVue,
  },
  mixins: [web3Modal],
  data() {
    return {
      mobileHide: true,
      queryString: '',
      queryResult: [],
      timer: null,
      theme: 'light',
      providerOptions: {
        walletconnect: {
          package: WalletConnectProvider,
          options: {
            infuraId: '-',
            chainId: '0x2710',
          },
        },
      },
      number: 0,
      balance: 0,
      // countNum: undefined,
      formData: undefined,
    };
  },
  computed: {
    ...mapState(['clientSize', 'language', 'web3Modal']),

    countNum() {
      return this.web3Modal.countNum || getStorage('countNum');
    },
    // storeLang() {
    //   console.log('language111=============>', this.language);
    //   this.$route.path('/');
    //   let menu;
    //   switch (this.language) {
    //     case 'zh-CN':
    //       menu = [
    //         'BCH网络项目汇总',
    //         '什么是比特币现金',
    //         'BCH每周要闻',
    //         'BCH专题',
    //       ];
    //       break;
    //     case 'en-US':
    //       menu = this.language;
    //       break;
    //     default:
    //       menu = this.language;
    //   }
    //   return menu;
    // },
  },
  watch: {
    //路由改变时，收起导航栏
    '$route.path'() {
      this.mobileHide = true;
    },
  },
  created() {
    if (
      window.matchMedia &&
      window.matchMedia('(prefers-color-scheme: dark)').matches
    ) {
      this.theme = 'dark';
    }
  },
  mounted() {
    this._getCurrentFormUrl();
    //监听页面滚动位置，改变导航栏的显示
    window.addEventListener('scroll', () => {
      //首页且不是移动端
      if (this.$route.name === 'home' && this.clientSize.clientWidth > 768) {
        if (window.scrollY > this.clientSize.clientHeight / 2) {
          this.$refs.nav.classList.remove('transparent');
        } else {
          this.$refs.nav.classList.add('transparent');
        }
      }
    });
    //监听点击事件，收起导航菜单
    document.addEventListener('click', (e) => {
      //遍历冒泡
      let flag = e.path.some((item) => {
        if (item === this.$refs.nav) return true;
      });
      //如果导航栏是打开状态，且点击的元素不是Nav的子元素，则收起菜单
      if (!this.mobileHide && !flag) {
        this.mobileHide = true;
      }
    });

    // 钱包
    this.$nextTick(async () => {
      const web3modal = this.$refs.web3modal;
      this.$store.commit('setWeb3Modal', web3modal);
      if (web3modal.cachedProvider) {
        this.connect();
      }
    });
    // this.countNum = getStorage('countNum');
  },
  methods: {
    toggle() {
      this.mobileHide = !this.mobileHide;
    },
    categoryRoute(name) {
      this.$router.push(`/category/${name}`);
    },
    debounceQuery(queryString, callback) {
      this.timer && clearTimeout(this.timer);
      this.timer = setTimeout(
        () => this.querySearchAsync(queryString, callback),
        1000
      );
    },
    querySearchAsync(queryString, callback) {
      if (
        queryString == null ||
        queryString.trim() === '' ||
        queryString.indexOf('%') !== -1 ||
        queryString.indexOf('_') !== -1 ||
        queryString.indexOf('[') !== -1 ||
        queryString.indexOf('#') !== -1 ||
        queryString.indexOf('*') !== -1 ||
        queryString.trim().length > 20
      ) {
        return;
      }
      getSearchBlogList(queryString)
        .then((res) => {
          if (res.code === 200) {
            this.queryResult = res.data;
            if (this.queryResult.length === 0) {
              this.queryResult.push({ title: '无相关搜索结果' });
            }
            callback(this.queryResult);
          }
        })
        .catch(() => {
          this.msgError('请求失败');
        });
    },
    handleSelect(item) {
      if (item.id) {
        this.$router.push(`/blog/${item.id}`);
      }
    },
    connect() {
      this.$store.dispatch('connect');
    },
    toCheck() {
      console.log('222');
      let url = 'https://checkbook.cash/';
      window.open(url, '_blank');
    },
    _getCurrentFormUrl() {
      getCurrentFormUrl().then((res) => {
        console.log(res, 'url11111');
        if (res.code === 200) {
          this.formData = res.data;
        }
      });
    },
  },
  watch: {},
};
</script>

<style>
.ui.fixed.menu .container {
  width: 1400px !important;
  margin-left: auto !important;
  margin-right: auto !important;
}

.ui.fixed.menu {
  transition: 0.3s ease-out;
}

.ui.inverted.pointing.menu.transparent {
  background: transparent !important;
}

.ui.inverted.pointing.menu.transparent .active.item:after {
  background: transparent !important;
  transition: 0.3s ease-out;
}

.ui.inverted.pointing.menu.transparent .active.item:hover:after {
  background: transparent !important;
}

.el-dropdown-link {
  outline-style: none !important;
  outline-color: unset !important;
  height: 100%;
  cursor: pointer;
}

.el-dropdown-menu {
  margin: 7px 0 0 0 !important;
  padding: 0 !important;
  border: 0 !important;
  background: #1b1c1d !important;
}

.el-dropdown-menu__item {
  padding: 0 15px !important;
  color: rgba(255, 255, 255, 0.9) !important;
}

.el-dropdown-menu__item:hover {
  background: rgba(255, 255, 255, 0.08) !important;
}

.el-popper .popper__arrow::after {
  content: none !important;
}

.popper__arrow {
  display: none !important;
}

.m-search {
  min-width: 220px;
  padding: 0 !important;
}

.m-search input {
  color: rgba(255, 255, 255, 0.9);
  border: 0px !important;
  background-color: inherit;
  padding: 0.67857143em 2.1em 0.67857143em 1em;
}

.m-search i {
  color: rgba(255, 255, 255, 0.9) !important;
}

.m-search-item {
  min-width: 350px !important;
}

.m-search-item li {
  line-height: normal !important;
  padding: 8px 10px !important;
}

.m-search-item li .title {
  text-overflow: ellipsis;
  overflow: hidden;
  color: rgba(0, 0, 0, 0.87);
}

.m-search-item li .content {
  text-overflow: ellipsis;
  font-size: 12px;
  color: rgba(0, 0, 0, 0.7);
}
</style>