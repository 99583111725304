var ko={
  home:'첫 페이지',
  category:'category',
  archives:'archives',
  moments:'moments',
  nav:'분류 안내',
  about:'about',
  smartBCH:'smartBCH 사이드체인',
  BCHBasedProjects :'BCH 프로젝트',
  WhatisBitcoinCash:'비트고인 캐시는 무엇인가',
  BCHWeeklyNews:'BCH 매주 뉴스',
  smartBCHTopic :'smartBCH 주제',
  view:'Read full aritcle',
  wallet:'Connect the wallet',
  airdrop:'Claim Airdrop',
  footer:{
    mobile:"휴대전화로 본 사이트 보기",
    new:'최신 문장',
  },
  blog:{
    author:'작성자:',
    time:'출판 날짜:',
    modified:'수정된 날짜:',
    desc1:'본 사이트 사용',
    desc2:'4.0인터내셔널 (cc by 4.0)',
    desc3:'저작물 공유 협의.자유롭게 복사, 인용할 수 있으며 상업적으로 사용할 수 있습니다.그러나 저자가 서명하고 글의 출처를 밝혀야 한다.',
  },
  comment:{
    close:'리뷰가 닫혔습니다.',
    comment:'코멘트 해주세요.',
    btn:'내 가 참관 해도 되겠습니까 😊',
    notice:'답장 가입',
    email:'답장 메시지를 받을 때 사용합니다',
    email_placeholder:'메일박스 (필수)',
    qq:'qq 번호를 입력하면 닉네임과 아바타도 자동으로 추출된다',
    qq_placeholder:'닉네임 (필수)',
    total:'조평',
    desc:'빨리 와서 소파 뺏어!',
    reply:'답장',
    close_reply:'답장 취소',  
    textarea:'댓글 수천만 개 중 친절한 첫 번째'
  },
  artice:{
    menu:'본문 목록',
    artice:' 무작위 기사',
    label:'태그 클라우드'
  },
  share:{
    desc:'공유할 수 있습니다.',
    copysuccess:"성공적인 링크 공유",
    copyerror:"링크를 공유하는 데 실패했습니다." 
  }
    
}

export default ko