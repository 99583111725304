var vi={
  home:'Trang chủ',
  category:'Phân loại',
  archives:'Kho lưu',
  moments:'Động lực',
  nav:'Chuyên mục',
  about:'về',
  smartBCH:'smartBCH Chuỗi kiểm tra',
  BCHBasedProjects :'Dự án trên nền tảng BCH',
  WhatisBitcoinCash:'Bitcoin Cash là gì',
  BCHWeeklyNews:'Bản tin BCH hàng tuần',
  smartBCHTopic :'smartBCH专题',
  view:'Read full aritcle',
  wallet:'Connect the wallet',
  airdrop:'Claim Airdrop',
  footer:{
    mobile:"Điện thoại di động xem trang web này",
    new:'Bài báo mới nhất',
  },
  blog:{
    author:'Akio:',
    time:'Thời gian xuất bản:',
    modified:'Cuối cùng sửa đổi:',
    desc1:'Trang web này',
    desc2:'Ký tên 4.0 quốc tế (CC BY 4.0)',
    desc3:'Thỏa thuận sáng tạo. Có thể được SAO chép, trích dẫn, và cho phép sử dụng thương mại. Nhưng bạn cần phải ký tên và cho biết nguồn gốc của bài viết.',
  },
  comment:{
    close :' ý kiến đóng cửa ',
    comment :' bình luận ',
    btn: 'có thể làm cho tôi đến thăm một chút không 😊',
    notice:'Đăng ký trả lời',
    email :' để nhận thư trả lời ',
    email_placeholder :' email (phải điền vào) ',
    qq :' nhập số qq sẽ tự động kéo biệt hiệu và avatar ',
    qq_placeholder :' nickname (phải điền vào) ',
    total :' bài bình luận ',
    desc:'Lại đây lấy cái ghế đi!',
    reply:' trả lời ',
    close_reply :' hủy bỏ trả lời ',
    textarea :' hàng ngàn lời bình luận, thân thiện đầu tiên '
  },
  artice:{
    menu :' thư mục của bài viết ',
    artice:'Những bài báo ngẫu nhiên',
    label:'Đám mây nhãn'
  },
  share:{
    desc:'Chia sẻ với:',
    CopySuccess :' chia sẻ các liên kết thành công ',    
    CopyError :' chia sẻ liên kết không thành công ',    
  }
}

export default vi