<template>
  <div>
    <div class="ui top segment" style="text-align: center">
      <h2 class="m-text-500">{{ categoryName }}</h2>
    </div>
    <BlogList
      :getBlogList="getBlogList"
      :blogList="blogList"
      :totalPage="totalPage"
    />
  </div>
</template>

<script>
import BlogList from '@/components/blog/BlogList';
import { getBlogListByCategoryName } from '@/api/category';

export default {
  name: 'Category',
  components: { BlogList },
  data() {
    return {
      blogList: [],
      totalPage: 0,
    };
  },
  watch: {
    //在当前组件被重用时，要重新获取博客列表
    '$route.fullPath'() {
      if (this.$route.name === 'category') {
        this.getBlogList();
      }
    },
  },
  created() {
    this.getBlogList();
  },
  computed: {
    categoryName() {
      return this.$route.params.name;
    },
  },
  methods: {
    getBlogList(pageNum) {
      getBlogListByCategoryName(this.categoryName, pageNum)
        .then((res) => {
          if (res.code === 200) {
            this.blogList = res.data.list;
            this.totalPage = res.data.totalPage;
            this.$nextTick(() => {
              Prism.highlightAll();
            });
          } else {
            this.msgError(res.msg);
          }
        })
        .catch(() => {
          this.msgError('请求失败');
        });
    },
  },
};
</script>

<style scoped>
</style>