import Vue from 'vue'
import VueI18n from 'vue-i18n'

import ElementLocale from 'element-ui/lib/locale'
import enLocale from 'element-ui/lib/locale/lang/en'//引入element英文语言包
import zhLocale from 'element-ui/lib/locale/lang/zh-CN'//引入element中文语言包
import koLocale from 'element-ui/lib/locale/lang/ko'//引入element韩文语言包
import viLocale from 'element-ui/lib/locale/lang/vi'//引入element越南文语言包


// 引入工具函数
import { getStorage, removeStorage, getBrowserLang } from './store'

import langZh from "@/assets/languages/zh.js"//引入中文语言包
import langEN from "@/assets/languages/en.js"//引入英文语言包
import langKO from "@/assets/languages/ko.js"//引入韩文语言包
import langVI from "@/assets/languages/vi.js"//引入越南文语言包

Vue.use(VueI18n)

// 获取当前语言（初始化时localStorage里没有存语言，默认为浏览器当前的语言）
const lang = getStorage('lang') || getBrowserLang()
 
const i18n = new VueI18n({
  locale: lang,//默认为中文
  messages: {
    'zh-CN': {...langZh,...zhLocale},
    'en-US': {...langEN,...enLocale},
    'Korean': {...langKO,...koLocale},
    'vi_VN': {...langVI,...viLocale}
  }
})
ElementLocale.i18n((key, value) => i18n.t(key, value))

export default i18n
