import axios from '@/plugins/axios'

export function getBlogList(pageNum,ids) {
	return axios({
		url: 'blogs',
		method: 'GET',
		params: {
			pageNum,
			ids
		},
		// paramsSerializer: function(ids){  //序列化操作  格式为：ids=2&ids=3&ids=1&type=1
		// 	return qs.stringify(ids,{arrayFormat:'repeat'})
		// }	
	})
}