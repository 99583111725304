// import {getLibrary} from "@/util/web3";
import {ethers} from "ethers";
import {parseInt} from 'lodash'
import { getUserReadCount } from '@/api/score';
import { setStorage } from '@/util/store.js'

const web3ModalStore = {
    state: {
        web3Modal: null,
        // library: getLibrary(),
        active: false,
        account: null,
        chainId: '0x2710',
        countNum:undefined
    },
    mutations: {
        setWeb3Modal(state, web3Modal) {
            state.web3Modal = web3Modal
        },
        setLibrary(state, library) {
            state.library = library
        },
        setActive(state, active) {
            state.active = active
        },
        setAccount(state, account) {
            state.account = account.toLowerCase()
        },
        setChainId(state, chainId) {
            state.chainId = chainId
        },
        setCountNum(state, countNum) {
            state.countNum = countNum
        }
    },
    actions: {
        async connect({state, commit, dispatch}) {
            console.log('state==>',state)
            const provider = await state.web3Modal.connect();
            await dispatch('switchChain')
            const library = new ethers.providers.Web3Provider(provider)
          
            library.pollingInterval = 12000
            commit('setLibrary', library)

            const accounts = await library.listAccounts()
            if (accounts.length > 0) {
                commit('setAccount', accounts[0])
                console.log('地址1',accounts[0])
                await dispatch('getReadCount')
            }
            const network = await library.getNetwork()
            commit('setChainId', network.chainId)
            commit('setActive', true)

            provider.on("connect", async (info) => {
                let chainId = parseInt(info.chainId)
                commit('setChainId', chainId)
                console.log("connect", info)
            });

            provider.on("accountsChanged", async (accounts) => {
                if (accounts.length > 0) {
                    commit('setAccount', accounts[0])
                    console.log('地址2',accounts[0])
                    await dispatch('getReadCount')
                } else {
                    await dispatch('resetApp')
                }
                console.log("accountsChanged")
            });
            provider.on("chainChanged", async (chainId) => {
                chainId = parseInt(chainId)
                commit('setChainId', chainId)
                console.log("chainChanged", chainId)
            });

        },
        async resetApp({state, commit}) {
            try {
                await state.web3Modal.clearCachedProvider();
            } catch (error) {
                console.error(error)
            }
            commit('setAccount', null)
            commit('setActive', false)
            // commit('setLibrary', getLibrary())
        },
        async getReadCount({state, commit}) {
            console.log('state1111',state)
            const params ={
                address : state.account
            }
            try {
              const res=  await getUserReadCount(params);
              console.log('res===>',res)
              if(res.code ===200){
                const countNum =res.data.adv_count + res.data.blog_count
                commit('setCountNum',countNum )
                setStorage('countNum', countNum)
              }
            } catch (error) {
                console.error(error)
            }
            
        },
        async switchChain(){
            let data = {
                chainId:'0x2710',
                rpcUrls:['https://global.uat.cash'],
                chainName:'Smart Bitcoin Cash',
                nativeCurrency:{
                    name: 'BCH',
                    symbol: 'BCH', // 2-6 characters long
                    decimals: 18,
                }
            };
	  	  try {
			let {chainId} = data;
	  	    await ethereum.request({
	  	      method: 'wallet_switchEthereumChain',
	  	      params: [{ chainId }],
	  	    });
	  	  } catch (switchError) {
	  	    // This error code indicates that the chain has not been added to MetaMask.
			console.log(switchError)
	  	    if (switchError.code === 4902) {
	  	      
			  this.addChain(data);
	  	    }
	  	    // handle other "switch" errors
	  	  }
	  }
    }
}

export default web3ModalStore;