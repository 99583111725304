import axios from "axios";
import NProgress from 'nprogress'
import 'nprogress/nprogress.css'
import config from '@/util/config.js';

console.log('config===>',config)

const request = axios.create({
	// baseURL: 'http://172.16.26.186:8090/',  //vpn
	baseURL: 'https://bch101.com/baseApi/',
	// baseURL: 'http://napi.lz82.com/baseApi/',
	// baseURL: config.baseURL,
	timeout: 10000,
})

// 请求拦截
request.interceptors.request.use(
	config => {
		NProgress.start()
		const identification = window.localStorage.getItem('identification')
		//identification存在，且是基于baseURL的请求
		if (identification && !(config.url.startsWith('http://') || config.url.startsWith('https://'))) {
			config.headers.identification = identification
		}
		// if (config.method === 'get' || config.method === 'GET') {
		// 	console.log('config.method======>',config.method)
		// 	// 如果是get请求，且params是数组类型如arr=[1,2]，则转换成arr=1&arr=2
		// 	config.paramsSerializer = function(params) {
		// 		return qs.stringify(params, { arrayFormat: 'repeat' })
		// 	}
		// }
		return config
	}
)

// 响应拦截
request.interceptors.response.use(
	config => {
		NProgress.done()
		const identification = config.headers.identification
		if (identification) {
			//保存身份标识到localStorage
			window.localStorage.setItem('identification', identification)
		}
		return config.data
	}
)

export default request