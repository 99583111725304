import Vue from 'vue'
import Vuex from 'vuex'
import state from './state'
import actions from './actions'
import mutations from './mutations'
import getters from './getters'
import web3ModalStore from "@/store/modules/web3Modal";
import userStore from "@/store/modules/user";

Vue.use(Vuex)

export default new Vuex.Store({
	state,
	actions,
	mutations,
	getters,
	modules: {
		web3Modal: web3ModalStore,
		user: userStore
}
})
