<template>
  <div>
    <el-button @click="_getScores()">获取积分</el-button>
    <el-button @click="_subScores()">增加积分</el-button>
    <el-button @click="_getUserReadCount()">获取阅读</el-button>
    <el-button @click="_getScoresRecs()">获取纪录</el-button>
    <p>{{ web3Modal.account }}</p>
    <p>{{ web3Modal }}</p>
  </div>
</template>

<script>
import web3Modal from '@/config/mixins';
import {
  getScores,
  subScores,
  getUserReadCount,
  getScoresRecs,
} from '@/api/score';
export default {
  mixins: [web3Modal],
  methods: {
    _getScores() {
      console.log('web3Modal==>', this.web3Modal);
      const params = {
        address: this.web3Modal.account,
      };
      getScores(params)
        .then((res) => {
          console.log(res);
          if (res.code === 200) {
            // console.log(res);
          } else {
            this.msgError(res.msg);
          }
        })
        .catch(() => {
          this.msgError('请求失败');
        });
    },
    _subScores() {
      const pamars = {
        address: this.web3Modal.account,
        read_id: 1,
        read_type: 1,
      };
      subScores(pamars)
        .then((res) => {
          console.log(res);
          if (res.code === 200) {
            // console.log(res);
          } else {
            this.msgError(res.msg);
          }
        })
        .catch(() => {
          this.msgError('请求失败');
        });
    },

    _getUserReadCount() {
      console.log('web3Modal==>', this.web3Modal);
      const params = {
        address: this.web3Modal.account,
      };
      getUserReadCount(params)
        .then((res) => {
          console.log(res);
          if (res.code === 200) {
            // console.log(res);
          } else {
            this.msgError(res.msg);
          }
        })
        .catch(() => {
          this.msgError('请求失败');
        });
    },
    _getScoresRecs() {
      console.log('web3Modal==>', this.web3Modal);
      const params = {
        page_index: 1,
        page_size: 10,
        address: this.web3Modal.account,
      };
      getScoresRecs(params)
        .then((res) => {
          console.log(res);
          if (res.code === 200) {
            // console.log(res);
          } else {
            this.msgError(res.msg);
          }
        })
        .catch(() => {
          this.msgError('请求失败');
        });
    },
  },
};
</script>
