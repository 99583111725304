import axios from '@/plugins/axios'
import config from '@/util/config.js';
// 获取链接
export function getCurrentFormUrl(params) {
	return axios({
		// 
		url: `${config.scoreURL}v1/activity/getCurrentFormUrl`,
		// url: `https://bch101.com/api/v1/activity/getCurrentFormUrl`,
		method: 'POST',
		data: {
			...params
		}
	})
}
