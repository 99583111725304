<template>
  <div class="advs-box m-margin-bottom-big ui ad" v-if="advsList.length > 0">
    <swiper class="swiper" :options="swiperOption">
      <swiper-slide
        v-for="(item, index) in advsList"
        :key="index"
        class="swiper-height"
      >
        <a :href="item.content" target="_blank" class="link">
          <img :src="item.image" class="image" @click="advsClick(item)" />
        </a>
      </swiper-slide>

      <div class="swiper-pagination" slot="pagination"></div>
    </swiper>
  </div>
</template>

<script>
import { Swiper, SwiperSlide } from 'vue-awesome-swiper';
// import 'swiper/css/swiper.css';
import 'swiper/swiper-bundle.css';
import Swiper2, { Navigation, Pagination, Autoplay } from 'swiper'; // 这行代码很关键
Swiper2.use([Navigation, Pagination, Autoplay]); // 这行代码很关键

import { getAdvsList } from '@/api/advs';
import web3Modal from '@/config/mixins';

import {
  getScores,
  subScores,
  getUserReadCount,
  getScoresRecs,
} from '@/api/score';

export default {
  name: 'swiper-example-vertical',
  title: 'Vertical slider',
  mixins: [web3Modal],
  components: {
    Swiper,
    SwiperSlide,
  },
  data() {
    return {
      swiperOption: {
        direction: 'vertical',
        pagination: {
          el: '.swiper-pagination',
          clickable: true,
        },
        // observer: true, //修改swiper自己或子元素时，自动初始化swiper
        // observeParents: true, //修改swiper的父元素时，自动初始化swiper
        autoplay: {
          stopOnLastSlide: true,
          delay: 3000,
        },
        loop: true,
      },
      advsList: [],
    };
  },
  created() {
    this._getAdvsList();
  },
  methods: {
    //广告
    _getAdvsList() {
      getAdvsList()
        .then((res) => {
          if (res.code === 200) {
            console.log('res=>', res);
            this.advsList = res.data.list || [];
          } else {
            this.msgError(res.msg);
          }
        })
        .catch(() => {
          this.msgError('请求失败');
        });
    },
    advsClick(item) {
      console.log('点击广告', item);
      this._subScores(item);
    },
    //增加广告积分
    _subScores(item) {
      const pamars = {
        address: this.web3Modal.account,
        read_id: item.id,
        read_type: 1,
      };
      subScores(pamars)
        .then((res) => {
          console.log(res.message, '1111');
          if (res.code === 200) {
            // console.log(res);
            this.msgSuccess('成功获取空投资格');
          } else {
            this.msgError(res.message);
          }
        })
        .catch(() => {
          this.msgError('请求失败');
        });
    },
  },
};
</script>

<style lang="scss">
.swiper {
  height: 126px;
  width: 100%;

  .swiper-slide {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    font-weight: bold;
    font-size: 12px;
    background-color: #333;
    height: 126px !important;
    .link {
      height: 100%;
      img {
        display: inline-block;
        height: 100%;
      }
    }
  }
  // /deep/.swiper-pagination-bullet-active {
  //   background: #16ab39;
  // }
}
</style>
<style>
.swiper-pagination-bullet-active {
  background: #16ab39;
}
.swiper-height {
  height: 126px !important;
}
.swiper {
  height: 126px !important;
  width: 100%;
}
.swiper img {
  display: inline-block;
  height: 100%;
}
</style>
