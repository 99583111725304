var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{ref:"nav",staticClass:"ui fixed inverted stackable pointing menu",class:{
    transparent: _vm.$route.name === 'home' && _vm.clientSize.clientWidth > 768,
  }},[_c('div',{staticClass:"ui container"},[_c('router-link',{attrs:{"to":"/"}},[_c('h3',{staticClass:"ui header item m-green"},[_vm._v(_vm._s(_vm.blogName))])]),_c('router-link',{staticClass:"item",class:{ 'm-mobile-hide': _vm.mobileHide, active: _vm.$route.name === 'home' },attrs:{"to":"/home"}},[_vm._v(" "+_vm._s(_vm.$t('home'))+" ")]),_c('router-link',{staticClass:"item",class:{
        'm-mobile-hide': _vm.mobileHide,
        active: _vm.$route.name === 'friends',
      },attrs:{"to":"/friends"}},[_vm._v(" "+_vm._s(_vm.$t('nav'))+" ")]),_c('router-link',{staticClass:"item",class:{
        'm-mobile-hide': _vm.mobileHide,
        active:
          decodeURIComponent(_vm.$route.path) === ("/category/" + (_vm.$t('smartBCH'))),
      },attrs:{"to":("/category/" + (_vm.$t('smartBCH')))}},[_vm._v(" "+_vm._s(_vm.$t('smartBCH'))+" ")]),_c('router-link',{staticClass:"item",class:{
        'm-mobile-hide': _vm.mobileHide,
        active:
          decodeURIComponent(_vm.$route.path) ===
          ("/category/" + (_vm.$t('BCHBasedProjects'))),
      },attrs:{"to":("/category/" + (_vm.$t('BCHBasedProjects')))}},[_vm._v(" "+_vm._s(_vm.$t('BCHBasedProjects'))+" ")]),_c('router-link',{staticClass:"item",class:{
        'm-mobile-hide': _vm.mobileHide,
        active:
          decodeURIComponent(_vm.$route.path) ===
          ("/category/" + (_vm.$t('WhatisBitcoinCash'))),
      },attrs:{"to":("/category/" + (_vm.$t('WhatisBitcoinCash')))}},[_vm._v(" "+_vm._s(_vm.$t('WhatisBitcoinCash'))+" ")]),_c('router-link',{staticClass:"item",class:{
        'm-mobile-hide': _vm.mobileHide,
        active:
          decodeURIComponent(_vm.$route.path) ===
          ("/category/" + (_vm.$t('BCHWeeklyNews'))),
      },attrs:{"to":("/category/" + (_vm.$t('BCHWeeklyNews')))}},[_vm._v(" "+_vm._s(_vm.$t('BCHWeeklyNews'))+" ")]),(_vm.formData && _vm.formData.form_url)?_c('a',{staticClass:"item",class:{
        'm-mobile-hide': _vm.mobileHide,
      },attrs:{"target":"_blank","href":_vm.formData.form_url}},[_vm._v(" "+_vm._s(_vm.$t('airdrop')))]):_vm._e(),_c('x-lang',{class:{
        'm-mobile-hide': _vm.mobileHide,
      }}),_c('el-autocomplete',{staticClass:"right item m-search",class:{ 'm-mobile-hide': _vm.mobileHide },attrs:{"fetch-suggestions":_vm.debounceQuery,"placeholder":"Search...","popper-class":"m-search-item"},on:{"select":_vm.handleSelect},scopedSlots:_vm._u([{key:"default",fn:function(ref){
      var item = ref.item;
return [_c('div',{staticClass:"title"},[_vm._v(_vm._s(item.title))]),_c('span',{staticClass:"content"},[_vm._v(_vm._s(item.content))])]}}]),model:{value:(_vm.queryString),callback:function ($$v) {_vm.queryString=$$v},expression:"queryString"}},[_c('i',{staticClass:"search icon el-input__icon",attrs:{"slot":"suffix"},slot:"suffix"})]),(_vm.web3Modal.active)?_c('el-button',{staticClass:"right item",staticStyle:{"height":"30px","margin-top":"10px"},attrs:{"size":"small","round":""},on:{"click":function($event){return _vm.toCheck()}}},[_vm._v(" "+_vm._s(_vm.web3Modal.account.slice(0, 5))+" ... "+_vm._s(_vm.web3Modal.account.slice(-5))+" "),_c('el-badge',{staticStyle:{"z-index":"10"},attrs:{"value":_vm.countNum}})],1):_c('el-button',{staticClass:"right item",staticStyle:{"height":"30px","margin-top":"10px"},attrs:{"size":"small","round":""},on:{"click":function($event){return _vm.connect()}}},[_vm._v(" "+_vm._s(_vm.$t('wallet'))+" ")]),_c('button',{staticClass:"ui menu green icon button m-right-top m-mobile-show",on:{"click":_vm.toggle}},[_c('i',{staticClass:"sidebar icon"})])],1),_c('web3-modal-vue',{ref:"web3modal",attrs:{"theme":_vm.theme,"provider-options":_vm.providerOptions,"cache-provider":""}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }