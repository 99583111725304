import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
//自定义css
import './assets/css/base.css'
//阿里icon
import './assets/css/icon/iconfont.css'
//typo.css
import "./assets/css/typo.css";
//semantic-ui-vue
import SuiVue from 'semantic-ui-vue'
import 'semantic-ui-css/semantic.min.css'
//element-ui
import Element from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
//全局css
import './assets/css/reset.css'
//moment
import './util/dateTimeFormatUtils.js'
//v-viewer
import 'viewerjs/dist/viewer.css'
import Viewer from 'v-viewer'
//directive
import './util/directive'

import i18n from './util/i18n.js'

import VueClipboard from 'vue-clipboard2'
 
Vue.use(VueClipboard)
Vue.use(SuiVue)
Vue.use(Element)
// Vue.use(Element, { locale })
Vue.use(Viewer)

Vue.prototype.msgSuccess = function (msg) {
	this.$message.success(msg)
}

Vue.prototype.msgError = function (msg) {
	this.$message.error(msg)
}

Vue.prototype.msgInfo = function (msg) {
	this.$message.info(msg);
}

const cubic = value => Math.pow(value, 3);
const easeInOutCubic = value => value < 0.5 ? cubic(value * 2) / 2 : 1 - cubic((1 - value) * 2) / 2;
//滚动至页面顶部，使用 Element-ui 回到顶部 组件中的算法
Vue.prototype.scrollToTop = function () {
	const el = document.documentElement
	const beginTime = Date.now()
	const beginValue = el.scrollTop
	const rAF = window.requestAnimationFrame || (func => setTimeout(func, 16))
	const frameFunc = () => {
		const progress = (Date.now() - beginTime) / 500;
		if (progress < 1) {
			el.scrollTop = beginValue * (1 - easeInOutCubic(progress))
			rAF(frameFunc)
		} else {
			el.scrollTop = 0
		}
	}
	rAF(frameFunc)
}

// import vuetify from './plugins/vuetify'
//根据路由信息更新网页标题
router.beforeEach((to, from, next) => {
  //路由发生变化修改页面title
  if (to.matched[0].meta.title) {
    document.title = to.matched[0].meta.title;
    document.getElementsByName("twitter:title")[0].content =
      to.matched[0].meta.title;
    document.getElementsByName("og:title")[0].content =
      to.matched[0].meta.title;
  }
  if (to.matched[0].meta.description) {
    document.getElementsByName("description")[0].content =
      to.matched[0].meta.description;
    document.getElementsByName("twitter:description")[0].content =
      to.matched[0].meta.description;
    document.getElementsByName("og:description")[0].content =
      to.matched[0].meta.description;
  }
  if (to.matched[0].meta.keywords) {
    document.getElementsByName("keywords")[0].content =
      to.matched[0].meta.keywords;
  }
  if (to.matched[0].meta.author) {
    document.getElementsByName("author")[0].content = to.matched[0].meta.author;
  }
  next();
});
////自定义修改网页标题，简介，关键字
Vue.directive("web-title", {
  update: function(el, binding, vnode, oldVnode) {
    const { value } = binding;
    if (el.dataset.title) {
      document.title = el.dataset.title;
      document.getElementsByName("twitter:title")[0].content =
      el.dataset.title;
      document.getElementsByName("og:title")[0].content =
      el.dataset.title;
    } else if (value && value.title) {
      document.title = value.title;
      document.getElementsByName("twitter:title")[0].content =
      value.title
      document.getElementsByName("og:title")[0].content =
      value.title
    }
  }
});
Vue.directive("web-description", {
  update: function(el, binding, vnode, oldVnode) {
    const { value } = binding;
    if (el.dataset.description) {
      document.getElementsByName("description")[0].content =
        el.dataset.description;
      document.getElementsByName("twitter:description")[0].content =
        el.dataset.description;
      document.getElementsByName("og:description")[0].content =
        el.dataset.description;
    } else if (value && value.description) {
      document.getElementsByName("description")[0].content = value.description;
      document.getElementsByName("twitter:description")[0].content = value.description;
      document.getElementsByName("og:description")[0].content = value.description;
    }
  }
});
Vue.directive("web-keywords", {
  update: function(el, binding, vnode, oldVnode) {
    const { value } = binding;
    if (el.dataset.keywords) {
      document.getElementsByName("keywords")[0].content = el.dataset.keywords;
    } else if (value && value.keywords) {
      document.getElementsByName("keywords")[0].content = el.dataset.keywords;
    }
  }
});
Vue.directive("web-author", {
  update: function(el, binding, vnode, oldVnode) {
    const { value } = binding;
    if (el.dataset.author) {
      document.getElementsByName("author")[0].content = el.dataset.author;
    } else if (value && value.author) {
      document.getElementsByName("author")[0].content = value.author;
    }
  }
});

Vue.config.productionTip = false

new Vue({
	i18n,
	router,
	store,
	// vuetify,
	render: h => h(App)
}).$mount('#app')
