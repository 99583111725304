<template>
  <div>
    <div class="ui top attached segment" style="text-align: center">
      <h2 class="m-text-500">导航</h2>
    </div>
    <!-- <div class="ui attached segment">
      <div class="ui link three doubling cards">
        <a
          :href="item.website"
          target="_blank"
          rel="external nofollow noopener"
          class="card"
          :style="randomRGB()"
          v-for="(item, index) in friendList"
          :key="index"
          @click="addViews(item.nickname)"
        >
          <div class="image">
            <img :src="item.avatar" onerror="this.src = '/img/error.png'" />
          </div>
          <div class="content">
            <div class="header">{{ item.nickname }}</div>
            <div class="description">{{ item.description }}</div>
          </div>
        </a>
      </div>
    </div> -->
    <div
      class="ui attached segment m-margin-bottom-small"
      v-for="(item, index) in friendList"
      :key="index"
    >
      <h1 class="post-title" itemprop="name headline">{{ item.title }}</h1>
      <div class="ui link three doubling cards">
        <a
          v-for="(itemList, index) in item.list"
          :key="index"
          :href="itemList.website"
          target="_blank"
          rel="external nofollow noopener"
          class="card"
          :style="randomRGB()"
        >
          <!-- <div class="image">
            <img
              :src="itemList.avatar"
              :data-src="itemList.avatar"
              onerror="this.src = '/img/error.png'"
            />
          </div> -->
          <div class="content">
            <div class="header">
              {{ itemList.nickname }}
            </div>
            <div class="description">{{ itemList.description }}</div>
          </div>
        </a>
      </div>
    </div>
    <!--页面描述-->
    <div class="ui teal attached segment">
      <div class="typo content" v-viewer v-html="info.content"></div>
    </div>
    <!--评论-->
    <div class="ui bottom teal attached segment threaded comments">
      <CommentList :page="2" :blogId="null" v-if="info.commentEnabled" />
      <h3 class="ui header" v-else>{{ $t('comment.close') }}</h3>
    </div>
  </div>
</template>

<script>
import { getData, addViewsByNickname } from '@/api/friend';
import { getNavData } from '@/api/nav';
import CommentList from '@/components/comment/CommentList';
import { sort_pro } from '@/util/sortArr.js';
import { mapState } from 'vuex';

export default {
  name: 'Friends',
  components: { CommentList },
  data() {
    return {
      friendList: [],
      bgColor: [
        '#1abc9c',
        '#2ecc71',
        '#3498db',
        '#9b59b6',
        '#34495e',
        '#f1c40f',
        '#e67e22',
        '#e74c3c',
        '#ee5a24',
        '#9980fa',
        '#8c7ae6',
        '#f79f1f',
      ],
      info: {
        content: '',
        commentEnabled: false,
      },
      navList: [],
    };
  },
  created() {
    this.getData();
    // this.getNavData();
  },
  computed: {
    ...mapState(['language']),
    langType() {
      let lang;
      switch (this.language) {
        case 'zh-CN':
          lang = '中文';
          break;
        case 'en-US':
          lang = 'English';
          break;
        case 'Korean':
          lang = 'Korean';
          break;
        case 'vi_VN':
          lang = 'Vietnam';
          break;
        default:
          lang = '中文';
      }
      return lang;
    },
  },
  methods: {
    getData() {
      getData()
        .then((res) => {
          if (res.code === 200) {
            // this.friendList = res.data.friendList;
            this.info = res.data.friendInfo;
            res.data.friendList = sort_pro(
              res.data.friendList.map((item) => ({
                title: item.type.split('-')[0],
                lang: item.type.split('-')[1],
                website: item.website,
                nickname: item.nickname,
                description: item.description,
              })),
              ['title', 'lang']
            );
            console.log('res.data.friendList====>', res.data.friendList);
            // let arr = [
            //   '中心化交易所',
            //   '去中心化交易所',
            //   '钱包',
            //   '跨链桥',
            //   '浏览器',
            //   '金融',
            //   '数据分析&信息',
            //   'NFT',
            //   'IDO募资',
            //   'DAPP&工具',
            //   '社区',
            // ];
            // arr.forEach((item) =>
            //   res.data.friendList.forEach(
            //     (value) =>
            //       item === value.title &&
            //       this.friendList.push({
            //         ...value,
            //       })
            //   )
            // );
            // this.friendList = this.friendList.filter((item) => {
            //   return item.lang === this.langType;
            // });

            this.friendList = res.data.friendList.filter((item) => {
              return item.lang === this.langType;
            });
            console.log('this.friendList===>', this.friendList);
          } else {
            this.msgError(res.msg);
          }
        })
        .catch(() => {
          this.msgError('请求失败');
        });
    },
    getNavData() {
      getNavData()
        .then((res) => {
          console.log(res, '1111');
          if (res.status === 200) {
            this.navList = res.data;
            console.log(this.navList, '222');
          } else {
            this.msgError(res.msg);
          }
        })
        .catch(() => {
          this.msgError('请求失败');
        });
    },
    addViews(nickname) {
      addViewsByNickname(nickname);
    },
    randomRGB() {
      const index = Math.floor(Math.random() * this.bgColor.length);
      return { backgroundColor: this.bgColor[index] };
    },
  },
};
</script>

<style scoped>
.card {
  border: 1px solid rgba(0, 0, 0, 0.08) !important;
  border-radius: 4px;
}
.card .image {
  width: 70px !important;
  margin: 10px auto 0px;
  background: unset !important;
}

.card .image img {
  border-radius: 100% !important;
}

.card .content {
  text-align: center;
  padding: 10px 14px !important;
  border-top: 0 !important;
}

.card .content * {
  /* color: #fff !important; */
}

.card .content .header {
  font-size: 16px !important;
}

.card .content .description {
  font-size: 12px !important;
  line-height: 20px;
  color: rgba(0, 0, 0, 0.56);
  height: 40px;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
}

.card .content .description {
  margin-top: 5px !important;
  margin-bottom: 7px;
}
.post-title {
  font-size: 21px;
  font-weight: 600;
  margin-bottom: 18px;
}
.post-title:before {
  font-weight: 600;
  content: '[';
  color: #0bb168;
  margin-right: 5px;
}
.post-title:after {
  font-weight: 600;
  content: ']';
  color: #0bb168;
  margin-left: 5px;
}
</style>